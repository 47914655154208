import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import withRoot from '../withRoot';

const styles = theme => ({
    root: {
    },
});

class FelonLogo extends React.Component {

    render() {
        const {classes} = this.props;
        const {logoStrike, logoBackground, backColor='#A9E9ED', strikeColor="black", width="100", height="100"} = this.props;

        return (
            <div className={classes.root}>
                    <svg width={width} height={height} viewBox="0 0 454 492" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <mask id="mask0" maskUnits="userSpaceOnUse" x="0" y="0" width="454" height="492">
                            <path
                                d="M85.1916 3.5591L451 3L391.173 120.831H199.952L113.148 489L3 356.073L85.1916 3.5591Z"
                                fill="white"/>
                            <path d="M212.253 210.148H363.636L307.584 326.441H184.297L212.253 210.148Z" fill="white"/>
                            <path
                                d="M85.1916 3.5591L451 3L391.173 120.831H199.952L113.148 489L3 356.073L85.1916 3.5591Z"
                                stroke="black" strokeWidth="0" strokeLinejoin="round"/>
                            <path d="M212.253 210.148H363.636L307.584 326.441H184.297L212.253 210.148Z" stroke="black"
                                  strokeWidth="0" strokeLinejoin="round"/>
                        </mask>
                        <g mask="url(#mask0)">
                            <path d="M458 -14H-10V506H458V-14Z" fill={logoBackground ? "url(#paint0_linear)" : backColor}/>
                            <path
                                d="M85.1916 3.5591L451 3L391.173 120.831H199.952L113.148 489L3 356.073L85.1916 3.5591Z"
                                stroke={strikeColor} strokeWidth={logoStrike ? "20" : "0"} strokeLinejoin="round"/>
                            <path d="M212.253 210.148H363.636L307.584 326.441H184.297L212.253 210.148Z" stroke={strikeColor}
                                  strokeWidth={logoStrike ? "20" : "0"} strokeLinejoin="round"/>
                        </g>
                        <defs>
                            <linearGradient id="paint0_linear" x1="224" y1="2.5" x2="224" y2="498"
                                            gradientUnits="userSpaceOnUse">
                                <stop stopColor="#91169D"/>
                                <stop offset="0.02" stopColor="#8E1A8E"/>
                                <stop offset="0.032" stopColor="#911992"/>
                                <stop offset="0.056" stopColor="#98179C"/>
                                <stop offset="0.07" stopColor="#90169D"/>
                                <stop offset="0.1" stopColor="#8F1990"/>
                                <stop offset="0.126" stopColor="#8E1A8D"/>
                                <stop offset="0.142" stopColor="#911895"/>
                                <stop offset="0.159" stopColor="#8E1A8E"/>
                                <stop offset="0.17" stopColor="#921895"/>
                                <stop offset="0.197" stopColor="#961799"/>
                                <stop offset="0.218" stopColor="#97169F"/>
                                <stop offset="0.239" stopColor="#901897"/>
                                <stop offset="0.254" stopColor="#9A169F"/>
                                <stop offset="0.283" stopColor="#90169E"/>
                                <stop offset="0.294" stopColor="#93169D"/>
                                <stop offset="0.305" stopColor="#8F1895"/>
                                <stop offset="0.332" stopColor="#95169E"/>
                                <stop offset="0.346" stopColor="#8F1993"/>
                                <stop offset="0.362" stopColor="#96179C"/>
                                <stop offset="0.381" stopColor="#94169D"/>
                                <stop offset="0.415" stopColor="#931798"/>
                                <stop offset="0.428" stopColor="#901991"/>
                                <stop offset="0.442" stopColor="#8F1992"/>
                                <stop offset="0.456" stopColor="#8F1A8E"/>
                                <stop offset="0.498" stopColor="#97169E"/>
                                <stop offset="0.511" stopColor="#98179C"/>
                                <stop offset="0.532" stopColor="#911992"/>
                                <stop offset="0.541" stopColor="#97169F"/>
                                <stop offset="0.56" stopColor="#911896"/>
                                <stop offset="0.581" stopColor="#8F1896"/>
                                <stop offset="0.6" stopColor="#9B16A0"/>
                                <stop offset="0.618" stopColor="#931895"/>
                                <stop offset="0.656" stopColor="#97169D"/>
                                <stop offset="0.679" stopColor="#8F1990"/>
                                <stop offset="0.689" stopColor="#931896"/>
                                <stop offset="0.72" stopColor="#8F1991"/>
                                <stop offset="0.734" stopColor="#96179B"/>
                                <stop offset="0.748" stopColor="#97169D"/>
                                <stop offset="0.764" stopColor="#901992"/>
                                <stop offset="0.788" stopColor="#9316A0"/>
                                <stop offset="0.808" stopColor="#8F1898"/>
                                <stop offset="0.831" stopColor="#93169F"/>
                                <stop offset="0.856" stopColor="#93179A"/>
                                <stop offset="0.872" stopColor="#8F1895"/>
                                <stop offset="0.894" stopColor="#921894"/>
                                <stop offset="0.914" stopColor="#911991"/>
                                <stop offset="0.942" stopColor="#90179A"/>
                                <stop offset="0.957" stopColor="#911894"/>
                                <stop offset="0.973" stopColor="#8F1993"/>
                                <stop offset="1" stopColor="#8F1896"/>
                            </linearGradient>
                        </defs>
                    </svg>
            </div>
        );
    }
}

FelonLogo.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(FelonLogo));
