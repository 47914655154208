import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import withRoot from '../withRoot';

const styles = theme => ({
	root: {
		width: '100%',
		height: '100%',
	},
	svg: {
		verticalAlign: 'middle',
		fill: theme.palette.text.primary,
	},
});

class BandcampLogo extends React.Component {

	render () {
		const {classes} = this.props;
		const {purple, black} = this.props;

		return (
			<div className={classes.root}>
				<svg viewBox="0 0 24 24" className={classes.svg}>
					<path fill={purple ? '#8E1A8D' : black ? 'inherit' : '#A9E9ED'} d="M22,6L15.5,18H2L8.5,6H22Z"/>
				</svg>
			</div>
		);
	}
}

BandcampLogo.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(BandcampLogo));
