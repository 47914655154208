import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {graphql, StaticQuery} from 'gatsby';
import withRoot from '../withRoot';
import FelonLogo from '../svg/FelonLogo';
import Social from './social';

const styles = theme => ({
	root: {
		textAlign: 'center',
		width: '100%',
		paddingBottom: theme.spacing.unit * 4,
		paddingTop: theme.spacing.unit * 4,
		background: theme.palette.type === 'light' ? 'linear-gradient(180deg, #DCFFFF 0%, rgba(255, 255, 255, 0)' +
			' 100%)' : 'linear-gradient(180deg, #8F1993 0%, rgba(32, 33, 36, 0) 100%)',
	},
	grid: {
		width: '100%',
		maxWidth: 1440,
		margin: 'auto',
	},
	email: {
		paddingTop: theme.spacing.unit,
	},
	social: {
		marginTop: theme.spacing.unit * 4,
	},
	inex: {
		paddingTop: theme.spacing.unit * 2,
	},
});

class Hero extends React.Component {

	render () {
		const {classes, data} = this.props;
		return (
			<div className={classes.root}>
				<Grid container spacing={3} justify={'space-around'} className={classes.grid}>
					<Grid item xs={6} md={3}>
						<FelonLogo width={'100%'} height={'100%'} logoBackground logoStrike/>
					</Grid>
				</Grid>
				<Grid container spacing={3} justify={'space-around'} className={classes.grid}>
					<Grid item xs={12}>
						<Typography variant={'h1'} color={'secondary'}>{data.site.siteMetadata.title}</Typography>
					</Grid>
				</Grid>
				<Grid container spacing={3} justify={'space-around'} className={classes.grid}>
					<Grid item xs={12} sm={8} md={6}>
						<Social purple className={classes.social}/>
					</Grid>
				</Grid>
			</div>
		);
	}
}

Hero.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(props => (
	<StaticQuery
		query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
		render={data => <Hero data={data} {...props} />}
	/>)));
