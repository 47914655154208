import React from 'react';
import PropTypes from 'prop-types';
import {graphql, Link, StaticQuery} from 'gatsby';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withRoot from '../withRoot';
import FelonLogo from '../svg/FelonLogo';
import Social from './social';

const styles = theme => ({
	root: {
		textAlign: 'center',
		width: '100%',
		paddingTop: theme.spacing.unit * 4,
		paddingBottom: theme.spacing.unit * 4,
		backgroundColor: '#5B92A6',
	},
	grid: {
		width: '100%',
		maxWidth: 1440,
		margin: 'auto',
	},
	email: {
		paddingTop: theme.spacing.unit,
	},
	social: {
		marginTop: theme.spacing.unit * 4,
	},
	inex: {
		paddingTop: theme.spacing.unit * 2,
	},
	link: {
		textDecoration: 'none',
	},
});

class Footer extends React.Component {

	render () {
		const {classes, data} = this.props;

		return (
			<div className={classes.root}>
				<Grid container spacing={3} justify={'space-around'} className={classes.grid}>
					<Grid item xs={12} sm={4} md={3}>
						<Link to={'/'}>
							<FelonLogo/>
						</Link>
						<Typography variant={'h3'} color={'primary'}>{data.site.siteMetadata.title}</Typography>

						<Typography variant={'caption'} color={'primary'}>© 2021</Typography>
					</Grid>
					<Grid item xs={12} sm={8} md={6}>
						<Typography variant={'h3'} color={'primary'}>Contact me</Typography>
						<a href={'mailto:contact@felononbass.com'} target="_blank" rel="noopener noreferrer"
						   className={classes.link}>
							<Typography variant={'caption'} color={'primary'}
							            className={classes.email}>contact@felononbass.com</Typography>
							<Social className={classes.social}/>
						</a>
						<a href={'https://inex.rocks'} target="_blank" rel="noopener noreferrer"
						   className={classes.link}>
							<Typography variant={'caption'} color={'primary'} className={classes.inex}>Site made with ♥
								by
								Inex Code.</Typography>
						</a>
					</Grid>
				</Grid>
			</div>
		);
	}
}

Footer.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(props => (
	<StaticQuery
		query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
		render={data => <Footer data={data} {...props} />}
	/>
)));