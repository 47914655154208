import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Hero from './hero';
import FelonLogo from '../svg/FelonLogo';
import BandcampLogo from '../svg/Bandcamp';
import Footer from '../components/footer';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import {Link} from 'gatsby';
import withRoot from '../withRoot';

const styles = theme => ({
	root: {
		textAlign: 'center',
	},
	homeButton: {
		height: 60,
		width: 60,
		verticalAlign: 'bottom',
	},
	toolbar: {
		paddingTop: 10,
		paddingBottom: 10,
	},
	grow: {
		flexGrow: 1,
	},
});

class Layout extends React.Component {

	render () {
		const {classes, home, pageName} = this.props;

		return (
			<div className={classes.root}>
				<Helmet defaultTitle={`FelonOnBass`} titleTemplate={`%s | FelonOnBass`}>
					<meta name="twitter:site" content="@FelonOnBass"/>
					<meta name="og:type" content="website"/>
					<meta name="og:site_name" content="FelonOnBass"/>
					<meta name={'theme-color'} content={'white'}/>
					<meta name="Description" content="Hi. I'm FelonOnBass. I make EDM. Neigh."/>
					<link
						rel="canonical"
						href={`https://felononbass.com`}
					/>
					<html lang="en"/>
				</Helmet>
				{!home ?
					<AppBar style={{background: 'transparent'}} position={'absolute'} color={'inherit'} elevation={0}>
						<Toolbar className={classes.toolbar}>
							<Link to={'/'}>
								<IconButton className={classes.homeButton}>
									<FelonLogo height={'39'} logoBackground/>
								</IconButton>
							</Link>
							<div className={classes.grow}/>
							<Typography variant='h1'
							            color='secondary'>{pageName}</Typography>
							<div className={classes.grow}/>
							<IconButton className={classes.homeButton} target="_blank" rel="noopener"
							            href={'https://felononbass.bandcamp.com'}>
								<BandcampLogo purple/>
							</IconButton>
						</Toolbar>
					</AppBar> : <Hero/>}
				{this.props.children}
				<Footer/>
			</div>
		);
	}
}

Layout.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(Layout));
