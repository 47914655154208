import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import withRoot from '../withRoot';
import IconButton from '@material-ui/core/IconButton/IconButton';
import SoundcloudLogo from '../svg/Soundcloud';
import YouTubeLogo from '../svg/YouTube';
import BandcampLogo from '../svg/Bandcamp';
import TwitterLogo from '../svg/Twitter';

const styles = theme => ({
	root: {
		display: 'flex',
		width: 'inherit',
		justifyContent: 'center',
		marginTop: theme.spacing.unit,
	},
	homeButton: {
		display: 'inline-block',
		flexShrink: 5,
		height: 64,
		width: 64,
	},
});

class Social extends React.Component {

	render () {
		const {classes, purple} = this.props;

		return (
			<div className={classes.root}>
				<IconButton className={classes.homeButton} target="_blank" rel="noopener"
				            href={'https://soundcloud.com/felononbass'}>
					<SoundcloudLogo purple={purple}/>
				</IconButton>
				<IconButton className={classes.homeButton} target="_blank" rel="noopener"
				            href={'https://www.youtube.com/channel/UCMK23fl3dUvk8AVwFDu0KEw'}>
					<YouTubeLogo purple={purple}/>
				</IconButton>
				<IconButton className={classes.homeButton} target="_blank" rel="noopener"
				            href={'https://felononbass.bandcamp.com'}>
					<BandcampLogo purple={purple}/>
				</IconButton>
				<IconButton className={classes.homeButton} target="_blank" rel="noopener"
				            href={'https://twitter.com/FelonOnBass'}>
					<TwitterLogo purple={purple}/>
				</IconButton>
			</div>
		);
	}
}

Social.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(Social));
