import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import withRoot from '../withRoot';

const styles = theme => ({
	root: {
		width: '100%',
		height: '100%',
	},
	svg: {
		verticalAlign: 'middle',
		fill: theme.palette.text.primary,
	},
});

class YouTubeLogo extends React.Component {

	render () {
		const {classes} = this.props;
		const {purple, black} = this.props;

		return (
			<div className={classes.root}>
				<svg viewBox="0 0 24 24" className={classes.svg}>
					<path fill={purple ? '#8E1A8D' : black ? 'inherit' : '#A9E9ED'}
					      d="M10,15L15.19,12L10,9V15M21.56,7.17C21.69,7.64 21.78,8.27 21.84,9.07C21.91,9.87 21.94,10.56 21.94,11.16L22,12C22,14.19 21.84,15.8 21.56,16.83C21.31,17.73 20.73,18.31 19.83,18.56C19.36,18.69 18.5,18.78 17.18,18.84C15.88,18.91 14.69,18.94 13.59,18.94L12,19C7.81,19 5.2,18.84 4.17,18.56C3.27,18.31 2.69,17.73 2.44,16.83C2.31,16.36 2.22,15.73 2.16,14.93C2.09,14.13 2.06,13.44 2.06,12.84L2,12C2,9.81 2.16,8.2 2.44,7.17C2.69,6.27 3.27,5.69 4.17,5.44C4.64,5.31 5.5,5.22 6.82,5.16C8.12,5.09 9.31,5.06 10.41,5.06L12,5C16.19,5 18.8,5.16 19.83,5.44C20.73,5.69 21.31,6.27 21.56,7.17Z"/>
				</svg>
			</div>
		);
	}
}

YouTubeLogo.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(YouTubeLogo));
